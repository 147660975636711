<template>
  <div class="d-flex flex-column fill-height pb-16">
    <PageHeader>
      <div class="d-flex justify-space-between align-center">
        <h1 class="d-flex align-center text-title-1 overflow-hidden">
          <UiBtn icon class="mr-1" color="gray-60" @click="$router.push({name: 'FilesByProjects'})">
            <IconArrowLeft width="16"/>
          </UiBtn>
          Files
          <IconDoubleChevronRight width="15" class="mx-4 accent-70--text flex-shrink-0"/>
          <span class="accent--text">Projects</span>
          <template v-if="project">
            <IconDoubleChevronRight width="15" class="mx-4 accent-70--text flex-shrink-0"/>
            <v-tooltip top color="rgba(47, 49, 53, 0.7)">
              <template #activator="{attrs, on}">
                <span class="accent--text text-truncate" v-bind="attrs" v-on="on">{{ project.title }}</span>
              </template>
              <div class="text-captions-1">
                {{ project.title }}
              </div>
            </v-tooltip>
          </template>
        </h1>
      </div>
    </PageHeader>

    <v-container>
      <div v-if="!loading" class="d-flex align-center justify-space-between mb-9">
        <div class="d-flex">
          <UiGridViewType class="mr-6" :list="$config.project.gridView.thumbnail"
                          :active="(getGridView.filesByStyles.gridView.type === 'thumbnail' && getGridView.filesByStyles.gridView)"
                          @setGridView="setGridView({key: 'filesByStyles', value: {gridView: $event, isGrid: true}})"/>

          <UiGridViewType class="mr-7" :list="$config.project.gridView.list"
                          :active="(getGridView.filesByStyles.gridView.type === 'list' && getGridView.filesByStyles.gridView)"
                          @setGridView="setGridView({key: 'filesByStyles', value: {gridView: $event, isGrid: false}})"/>

          <v-switch
            flat
            dense
            hide-details
            inset
            @change="$router.push({name: 'FilesAll', params: {space_id: $route.params.space_id}, query: {project_id: $route.params.project_id}})"
            class="switcher my-0"
          >
            <template #label>
              <span class="text-captions-1 font-weight-semi-bold accent--text">View all files</span>
            </template>
          </v-switch>
        </div>

        <div class="d-flex align-center">
          <UiFilter
            :filterData="filterData"
            offset-y
            allow-overflow
            left
            clear-btn
            @setFilters="setFilters"
            openFilterBtnClasses="mr-4"
          />
          <UiSort
            class="mr-6"
            mandatory1
            mandatory2
            :list="sortList1"
            :list2="sortList2"
            :value1="sort_1"
            :value2="sort_2"
            @setSort1="value => this.setSort('sort_1', value)"
            @setSort2="value => this.setSort('sort_2', value)"
          />
          <UiSearch style="width: 194px" @onSearch="searchStyles" />
        </div>
      </div>

      <div v-if="stylesLoading || loading" class="d-flex justify-center pt-16">
        <v-progress-circular
          :size="100"
          color="accent"
          indeterminate
        />
      </div>

      <template v-else-if="stylesListFiltered.length && !loading && !stylesLoading">
        <div v-show="getGridView.filesByStyles.isGrid" :class="['card-wr', getGridView.filesByStyles.gridView.slug]">
          <UiGridCard
            v-for="style in stylesListFiltered"
            :key="style.id"
            :item="style"
            :itemStatus="style.status || null"
            :selectable="false"
            :selected="style.checked"
            :to="go(style)"
          >
            <template #header v-if="style.items.length">
              <div class="d-flex justify-space-between py-3 px-3">
                <UiAvatar
                  size="22"
                  :text="style.items[0].assignee.first_name"
                  :src="style.items[0].assignee.avatar ? style.items[0].assignee.avatar.url : ''"
                />
                <UiSelectedIndicator
                  :selected="style.checked"
                  @click.prevent="updateStyle({id: style.id, checked: !style.checked})"
                />
              </div>
            </template>

            <template v-slot:image>
              <div class="d-flex align-center justify-center flex-grow-1 pb-5">
                <IconFolder width="38%" class="gray-30--text"/>
              </div>
            </template>

            <template v-slot:bottom>
              <div class="px-2">
                <UiChangeNameInput
                  v-if="style.showRenameInput"
                  v-model="style.newTitle"
                  placeholder="Enter file name"
                  :rules="'required|min:3|max:128'"
                  @onSave="updateStyleName(style)"
                  @onClose="style.showRenameInput = false"
                />
                <v-tooltip top color="accent" v-else>
                  <template v-slot:activator="{ on, attrs }">
                    <h6
                      v-bind="attrs"
                      v-on="on"
                      class="mb-1 text-body font-weight-semi-bold gray-100--text text-truncate"
                      @click.prevent="canRename ? style.showRenameInput = true : $event.preventDefault"
                    >
                      {{style.title}}
                    </h6>
                  </template>
                  <span class="white--text font-weight-medium ">{{ project.title }}</span>
                </v-tooltip>


                <div class="d-flex justify-end align-end">
                  <div v-if="style.items.length" class="mr-auto">
                    <span class="text-captions-1 gray-60--text">Updated:</span>
                    <p class="mb-0 text-captions-1 font-weight-bold gray-80--text">{{timeDistance(style.items[0].updated_at)}}</p>
                  </div>
                  <FilesStyleMenu :style-item="style" :styles-list="stylesList" @updateStylesList="updateStylesList"/>
                </div>
              </div>
              <div v-if="style.loading" class="absolute inset-0 d-flex justify-center align-center gray-30" style="z-index: 2;" @click.stop>
                <v-progress-circular
                  :size="64"
                  color="accent"
                  indeterminate
                />
              </div>
            </template>
          </UiGridCard>
        </div>

        <UiListCard
          v-show="!getGridView.filesByStyles.isGrid"
          v-for="style in stylesListFiltered"
          :key="style.id"
          :item="style"
          :selected="style.checked"
          :selectable="false"
          :class="[`style-item mb-3 relative ${getGridView.filesByStyles.gridView.slug}`]"
          :to="go(style)"
        >
          <template v-slot:image>
            <UiSelectedIndicator
              class="absolute mt-2 ml-2"
              :selected="style.checked"
              @click.prevent="updateStyle({id: style.id, checked: !style.checked})"
            />
            <div class="d-flex align-center justify-center fill-width fill-height">
              <IconFolder width="28%" class="gray-30--text"/>
            </div>
          </template>

          <template v-slot:body>
            <div class="d-flex align-center fill-width fill-height">
              <div class="style-item-section px-5 mr-auto">
                <div class="d-flex align-center">
                  <UiChangeNameInput
                    v-if="style.showRenameInput"
                    v-model="style.newTitle"
                    placeholder="Enter file name"
                    :rules="'required|min:3|max:128'"
                    @onSave="updateStyleName(style)"
                    @onClose="style.showRenameInput = false"
                  />
                  <v-tooltip top color="accent" v-else>
                    <template v-slot:activator="{ on, attrs }">
                      <p
                        v-bind="attrs"
                        v-on="on"
                        @click.prevent="canRename ? style.showRenameInput = true : $event.preventDefault"
                        class="mb-1 text-body font-weight-semi-bold gray-100--text text-no-wrap text-overflow-ellipsis overflow-hidden"
                      >
                        {{style.title}}
                      </p>
                    </template>
                    <span class="white--text font-weight-medium ">{{ project.title }}</span>
                  </v-tooltip>

                </div>
                <p v-if="style.items.length" class="mb-1 text-captions-1 gray-60--text">
                  Updated: <strong class="font-weight-semi-bold">{{ timeDistance(style.items[0].updated_at) }}</strong>
                </p>
              </div>

              <div class="style-item-section border-left px-5">
                <p class="mb-0 text-captions-1">
                  <strong class="font-weight-bold accent--text">{{ style.total_files }}</strong>
                  {{ style.total_files === 1 ? 'File' : 'Files'}}
                </p>
              </div>

              <div class="style-item-section border-left px-5">
                <div v-if="style.items.length" class="text-captions-1 text-overflow-ellipsis text-no-wrap overflow-hidden">
                  By:
                  <UiAvatar
                    size="22"
                    class="ml-2 mr-1"
                    :text="style.items[0].assignee.first_name"
                    :src="style.items[0].assignee.avatar ? style.items[0].assignee.avatar.url : ''"
                  />
                  {{ style.items[0].assignee.first_name }} {{ style.items[0].assignee.last_name }}
                </div>
              </div>

              <div class="style-item-section border-left px-5">
                <div class="d-flex justify-end">
                  <FilesStyleMenu :style-item="style" :styles-list="stylesList" @updateStylesList="updateStylesList"/>
                </div>
              </div>
            </div>
          </template>
          <template #footer>
            <div v-if="style.loading" class="absolute inset-0 d-flex justify-center align-center gray-30" style="z-index: 2;" @click.stop>
              <v-progress-circular
                :size="48"
                color="accent"
                indeterminate
              />
            </div>
          </template>
        </UiListCard>

        <PageFooter v-if="showBulkActions" class="px-0">
          <BulkActionsBar :styles-list="stylesList" @updateStylesList="updateStylesList"/>
        </PageFooter>
      </template>

      <v-flex
        v-if="!stylesListFiltered.length && !loading && !stylesLoading"
        class="d-flex justify-center align-center py-16 font-weight-semi-bold"
        style="font-size: 28px;"
      >
        No Data
      </v-flex>

    </v-container>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {differenceInDays, format, formatDistanceToNow} from "date-fns";
import PageHeader from "@/components/layout/PageHeader";
import PageFooter from "@/components/layout/PageFooter";
import UiBtn from "@/components/UI/UiBtn";
import UiGridViewType from "@/components/UI/UiGridViewType";
import UiSort from "@/components/UI/UiSort";
import UiFilter from "@/components/UI/UiFilter";
import UiSearch from "@/components/UI/UiSearch";
import UiAvatar from "@/components/UI/UiAvatar";
import UiGridCard from "@/components/UI/UiGridCard";
import UiListCard from "@/components/UI/UiListCard";
import UiSelectedIndicator from "@/components/UI/UiSelectedIndicator";
import UiChangeNameInput from "@/components/UI/UiChangeNameInput";
import FilesStyleMenu from "@/views/files/by-styles/FilesStyleMenu";
import BulkActionsBar from "@/views/files/by-styles/BulkActionsBar";

export default {
  name: 'FilesByStylesPage',
  components: {
    IconArrowLeft: () => import('@/components/icons/IconArrowLeft'),
    IconFolder: () => import('@/components/icons/IconFolder'),
    IconDoubleChevronRight: () => import('@/components/icons/IconDoubleChevronRight'),

    PageHeader,
    PageFooter,
    UiBtn,
    UiGridViewType,
    UiSort,
    UiFilter,
    UiSearch,
    UiAvatar,
    UiGridCard,
    UiListCard,
    UiSelectedIndicator,
    UiChangeNameInput,
    FilesStyleMenu,
    BulkActionsBar,
  },
  data() {
    return {
      loading: true,
      stylesLoading: false,
      project: null,
      search: '',
      filters: {
        module: null,
        tags: [],
        statuses: [],
      },
      filterData: [],
      stylesList: [],
      designModules: [],
      sortList1: [
        {name: 'Name', slug: 'title'},
        {name: 'Modified', slug: 'updated_at'},
      ],
      sortList2: [
        {name: 'Ascending', slug: 'asc'},
        {name: 'Descending', slug: 'desc'}
      ],
      sort_1: {name: 'Modified', slug: 'updated_at'},
      sort_2: {name: 'Ascending', slug: 'asc'},
    }
  },
  computed: {
    ...mapGetters([
      'getPermission',
      'getGridView'
    ]),
    canRename() {
      return this.getPermission(this.$route.params.project_id).design['can-rename-style-design'];
    },
    stylesListFiltered() {
      return this.stylesList.filter(project => project.total_files);
    },
    showBulkActions() {
      return this.stylesList.some(style => style.checked);
    },
  },
  async created() {
    const projectRes = await this.$api.project.get(this.$route.params.project_id);
    this.project = projectRes.data;


    this.designModules = this.project.modules.filter(module => module.type === this.$config.project.moduleTypes.design);
    const allTagsId = this.project.tag.map(tag => tag.id);

    const {tags, module, statuses, search = '', sort_1, sort_2} = this.$route.query;
    const moduleWithFiles = this.designModules.find(module => module.total_files)
    const filters = {
      tags: !tags ? allTagsId : tags.split(',').map(item => +item),
      module: !module ? (moduleWithFiles?.id || this.designModules[0].id) : +module,
      statuses: !statuses ? [] : statuses.split(',').map(item => +item),
    };

    if (this.$route.params.space_id !== 'default') {
      filters.space_id = this.$route.params.space_id
      await this.$store.dispatch('setActiveSpace', this.$route.params.space_id)
    } else {
      filters.space_id = null
    }

    if (sort_1 !== '' && sort_1 !== undefined) {
      this.sort_1 = this.sortList1.find(sort => sort.slug === sort_1);
    }

    if (sort_2 !== '' && sort_2 !== undefined) {
      this.sort_2 = this.sortList2.find(sort => sort.slug === sort_2);
    }

    await this.$router.$updateQueryParams(filters);

    this.search = search;
    this.filters = filters;
    this.initFiltersData();

    await this.loadStylesList();
    this.loading = false;
  },
  methods: {
    ...mapActions([
      'setGridView'
    ]),
    async loadStylesList() {
      this.stylesLoading = true;

      try {
        const stylesListRes = await this.$api.projectModuleDesign.list(this.$route.params.project_id, this.filters.module, {
          ...this.filters,
          sort_by: `${this.sort_1.slug}|${this.sort_2.slug}`,
        });
        if (typeof stylesListRes === 'string' && stylesListRes.length === 0) {
          this.stylesList = [];
        } else {
          this.stylesList = stylesListRes.data.map(style => ({
            ...style,
            newTitle: style.title,
            showRenameInput: false,
          }));
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.stylesLoading = false;
      }
    },
    initFiltersData() {
      const existingFilters = this.filters;
      const statuses = Object.keys(this.$config.project.statusLabels).reduce((acc, status) => {
        if (+status !== this.$config.project.status.draft) {
          acc.push({id: +status, title: this.$config.project.statusLabels[status]});
        }
        return acc;
      }, []);

      this.filterData = [
        {
          type: 'module',
          title: 'Design Module',
          list: this.designModules,
          isMandatory: true,
          activeFilters: existingFilters['module'] || []
        },
        {
          type: 'tags',
          title: 'Tags',
          list: this.project.tag,
          isMultiple: true,
          isMandatory: true,
          activeFilters: existingFilters['tags'] || []
        },
        {
          type: 'statuses',
          title: 'Status',
          list: statuses,
          isMultiple: true,
          activeFilters: existingFilters['statuses'] || []
        },
      ]
    },
    setFilters ({data: newVal}) {
      this.filterData = newVal;
      this.filterData.forEach(el => {
        if(Array.isArray(el.activeFilters)) {
          this.filters[el.type] = el.activeFilters.map(i => i.toString());
        } else {
          if(el.activeFilters) {
            this.filters[el.type] = el.activeFilters;
          } else {
            this.filters[el.type] = [];
          }
        }
      });

      this.$router.$updateQueryParams(this.filters);
      this.loadStylesList();
    },
    searchStyles(search) {
      this.search = search;

      this.$router.$updateQueryParams({search});
      this.loadStylesList();
    },
    setSort(key, value) {
      this[key] = value;
      this.$router.$updateQueryParams({[key]: value.slug});

      this.loadStylesList();
    },
    updateStylesList(data) {
      this.stylesList = data;
    },
    updateStyle(data) {
      this.stylesList = this.stylesList.map(style => style.id === data.id ? {...style, ...data} : style);
    },
    async updateStyleName(style) {
      if (!this.canRename) return;

      this.updateStyle({id: style.id, loading: true});

      try {
        await this.$api.projectModuleDesign.updateDesignStyleName(this.$route.params.project_id, this.$route.query.module, style.id, {title: style.newTitle});
        this.updateStyle({id: style.id, title: style.newTitle});
      } catch (error) {
        console.error(error);
      } finally {
        this.updateStyle({id: style.id, loading: false, showRenameInput: false});
      }
    },
    timeDistance(date) {
      const now = new Date();
      if (differenceInDays(new Date(date), now) < 0) {
        return format(new Date(date), 'MM-dd-yyyy');
      } else {
        const res = formatDistanceToNow(new Date(date), {addSuffix: true});
        return res.replace('about ', '');
      }
    },
    go(style) {
      if(style.showRenameInput) return
      return {
        name: 'FilesByVersions',
        params: {
          project_id: this.project.id,
          style_id: style.id,
          space_id: this.$route.params.space_id
        },
        query: {
          module: this.filters.module,
        }
      };
    }
  },
}
</script>

<style scoped lang="scss">
.switcher {
  ::v-deep .v-input--switch__thumb {
    top: calc(50% - 5px);
    width: 10px;
    height: 10px;
  }
  &.v-input--switch--inset ::v-deep .v-input--switch__track {
    top: calc(50% - 7px);
    left: -2px;
    height: 14px;
    width: 24px;
    background-color: var(--v-gray-60-base);
  }
  ::v-deep .v-input--selection-controls__ripple {
    top: calc(50% - 21px);
    left: -11px;
  }
  ::v-deep .v-input--selection-controls__input {
    width: 24px;
  }
}

.card-wr {
  display: grid;
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  &.large {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  &.medium {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  &.small {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .card {
    &:not(.selected) {
      border-color: var(--v-gray-30-base);
    }
    &:hover {
      border-color: var(--v-gray-60-base);
      background-color: var(--v-gray-10-base);
    }
  }
}

.style-item {
  border-color: var(--v-gray-30-base);

  .style-item-section {
    width: 25%;
  }

  ::v-deep .ui-list-card__image {
    padding-bottom: 0;
    background-color: var(--v-gray-10-base);
  }

  .border-left::before {
    content: '';
    position: absolute;
    top: 18px;
    bottom: 18px;
    margin-left: -20px;
    border-left: 1px solid var(--v-gray-20-base);
  }
}

</style>
